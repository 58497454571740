import React from 'react'
import { SectionFeaturedTutorials } from '../../../types/sections'
import { Box, Button, Flex, Heading, Image } from '../../../atoms'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { TutorialCard } from '../../resources/tutorials'
import { FeaturedTutorialsCol, FeaturedTutorialsRow } from './FeaturedTutorials.styled'

function FeaturedTutorials(props: SectionFeaturedTutorials): JSX.Element {
  const { sectionId, id, headline, icon, button, tutorials } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row between={'xs'}>
          <Col xs={12} md={4}>
            <Flex flexDirection={'column'} gap={[4, 4, 6]}>
              {icon && (
                <Box display={['none', 'none', 'block']}>
                  <Image alt={icon.alt ?? headline} title={icon.title ?? headline} image={icon.gatsbyImageData} />
                </Box>
              )}
              <Box>
                <Heading as={'h2'} type='heading1'>
                  {headline}
                </Heading>
              </Box>
              {button && button[0] && (
                <Box>
                  <Button link={button[0].link} label={button[0].label} buttonType={button[0].buttonType} />
                </Box>
              )}
            </Flex>
          </Col>
          <Col xs={12} md={7}>
            {tutorials.length > 0 && (
              <Box mt={[6, 6, 0]}>
                <FeaturedTutorialsRow className={'noscrollBar'} style={{ rowGap: '32px' }}>
                  {tutorials.map((tutorial) => (
                    <FeaturedTutorialsCol key={tutorial.id} xs={10} sm={5} md={6}>
                      <TutorialCard tutorial={tutorial} />
                    </FeaturedTutorialsCol>
                  ))}
                </FeaturedTutorialsRow>
              </Box>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default FeaturedTutorials
