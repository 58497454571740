import React from 'react'
import { Box, Button, Flex, Heading, Image, Tag, Text } from '../../../../atoms'
import { StaticImage } from 'gatsby-plugin-image'

import { SECTION_MARGIN } from '../../../../utils/constants'

import { StyledAnimatedDot, StyledGridImage, StyledIconContainer } from './TutorialHero.styled'
import { Grid } from 'react-styled-flexboxgrid'
import { ITutorial } from '../../../../types/resources'
import { PageInfoLinkProps } from '../../../../types/pages'
import { Link } from 'gatsby'
import { useLocale } from '../../../../hooks/useLocale'

export interface TutorialHeroProps extends Pick<ITutorial, 'name' | 'excerpt' | 'integrations' | 'buttons'> {
  tagLabel: string
  tagLink: PageInfoLinkProps
}

const TutorialHero: React.FC<TutorialHeroProps> = (props) => {
  const { tagLabel, tagLink, name, excerpt, integrations, buttons } = props

  const { localeSlug } = useLocale()

  return (
    <Box as={'section'} my={SECTION_MARGIN} overflow={'hidden'} position={'relative'}>
      <StyledGridImage display={['none', 'none', 'block']} position={'absolute'} left={0} maxWidth={'200px'}>
        <StaticImage src={'../../../../images/grid.png'} alt='' placeholder='none' />
      </StyledGridImage>
      <StyledGridImage
        display={['none', 'none', 'block']}
        position={'absolute'}
        right={0}
        bottom={0}
        maxWidth={'200px'}
        overflow={'hidden'}
      >
        <StaticImage src={'../../../../images/grid.png'} alt='' placeholder='none' />
      </StyledGridImage>
      <Grid>
        <Flex position={'relative'} flexDirection={'column'} alignItems={'center'} gap={[40, 60, 80]}>
          <Box display={['none', 'none', 'block']} right={0} top={0} position={'absolute'} width={47} height={47}>
            <StaticImage src={'../../../../images/synapse_2.png'} alt='' placeholder='none' />
          </Box>
          <Box display={['none', 'none', 'block']} left={0} bottom={100} position={'absolute'} width={47} height={47}>
            <StaticImage src={'../../../../images/synapse_1.png'} alt='' placeholder='none' />
          </Box>
          <Flex width={'100%'} alignItems={'center'} flexDirection={'column'} gap={[16, 20, 24]}>
            <Link to={`/${localeSlug}${tagLink.slug}`}>
              <Tag light label={tagLabel} color='blue' />
            </Link>
            <Box width={['100%', '75%', '50%']}>
              <Heading type='display' as={'h1'} textAlign={'center'}>
                {name}
              </Heading>
            </Box>
          </Flex>
          <Flex justifyContent={'space-between'} width={['100%', '80%', '80%']} position={'relative'} mt={[20, 20, 40]}>
            <StyledAnimatedDot height={[10, 20, 20]}>
              <Box as={'span'} height={[4, 8, 8]} width={[4, 8, 8]} />
            </StyledAnimatedDot>
            {integrations && integrations[0]?.icon && (
              <Box position={'relative'} width={'25%'}>
                <StyledIconContainer
                  backgroundColor={'white'}
                  p={[1, 2, 4]}
                  position={'absolute'}
                  zIndex={2}
                  width={'60%'}
                  top={'-40%'}
                  left={'50%'}
                >
                  <Image
                    alt={integrations[0].icon.alt || ''}
                    title={integrations[0].icon.title || ''}
                    image={integrations[0].icon.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </StyledIconContainer>
                <Box position={'relative'} zIndex={0}>
                  <StaticImage src={'../../../../images/iconBase.png'} alt='' placeholder='none' />
                </Box>
              </Box>
            )}

            <Box position={'relative'} maxWidth={'25%'}>
              <StyledIconContainer position={'absolute'} zIndex={2} width={'60%'} top={'-40%'} left={'50%'}>
                <StaticImage
                  src={'../../../../images/dinmo.png'}
                  alt='dinmo logo'
                  placeholder='none'
                  objectFit='contain'
                />
              </StyledIconContainer>
              <Box position={'relative'} zIndex={0}>
                <StaticImage src={'../../../../images/iconBase.png'} alt='' placeholder='none' />
              </Box>
            </Box>
            {integrations && integrations[1]?.icon && (
              <Box position={'relative'} maxWidth={'25%'}>
                <StyledIconContainer
                  backgroundColor={'white'}
                  p={[1, 2, 4]}
                  position={'absolute'}
                  zIndex={2}
                  width={'60%'}
                  top={'-40%'}
                  left={'50%'}
                >
                  <Image
                    alt={integrations[1].icon.alt || ''}
                    title={integrations[1].icon.title || ''}
                    image={integrations[1].icon.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </StyledIconContainer>
                <Box position={'relative'} zIndex={0}>
                  <StaticImage src={'../../../../images/iconBase.png'} alt='' placeholder='none' objectFit='contain' />
                </Box>
              </Box>
            )}
          </Flex>
          <Flex width={'100%'} alignItems={'center'} flexDirection={'column'} gap={[16, 20, 24]}>
            <Box width={['100%', '80%', '70%']}>
              <Text textAlign={'center'} type={'large'}>
                {excerpt}
              </Text>
            </Box>
            <Flex justifyContent={'center'} gap={16} pt={16} flexWrap={'wrap'}>
              {buttons.map((button) => {
                return <Button link={button.link} buttonType={button.buttonType} label={button.label} key={button.id} />
              })}
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  )
}

export default TutorialHero
