import styled from 'styled-components'
import { Box } from '../../../../atoms'

export const StyledIconContainer = styled(Box)`
  transform: translateX(-50%);
  img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
`

export const StyledAnimatedDot = styled(Box)`
  position: absolute;
  width: 75%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  span {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    border-radius: 50%;
    background: black;
    animation: dotAnimation infinite 4s linear alternate;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 0;
    border-top: 1px dashed black;
  }

  @keyframes dotAnimation {
    from {
      left: 0;
    }
    to {
      left: 100%;
    }
  }
`

export const StyledGridImage = styled(Box)`
  overflow: hidden;
  &:first-of-type {
    transform: translateX(-50%);
  }
  &:nth-of-type(2) {
    img {
      transform: translateX(50%);
    }
  }
`
