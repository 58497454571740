import React from 'react'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Flex, TemplateTag } from '../../../../atoms'
import { StructuredTextContent } from '../../../commons/StructuredText/StructuredTextContent'
import StructuredTextSummary from '../../../commons/StructuredTextSummary/StructuredTextSummary'
import PostSide from '../../../blog/PostSide'
import PostShare from '../../../blog/PostShare'
import { getUrlToShare } from '../../../../utils/commons'
import { ITutorial } from '../../../../types/resources'
import { ButtonProps } from '../../../../types/buttons'

interface TutorialArticleProps extends Pick<ITutorial, 'tags' | 'content'> {
  articleSideButton: ButtonProps[]
  articleSideHeadline: string
  articleSideTableOfContentLabel: string
  articleSideShareLabel: string
}

const TutorialArticle: React.FC<TutorialArticleProps> = ({
  tags,
  content,
  articleSideButton,
  articleSideHeadline,
  articleSideTableOfContentLabel,
  articleSideShareLabel,
}) => {
  return (
    <Box as={'section'} position={'relative'}>
      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <StructuredTextContent content={content} />

            {tags.length > 0 && (
              <Flex flexWrap={'wrap'} gap={16} mt={[6, 7, 8]}>
                {tags.map((tag) => (
                  <TemplateTag key={tag.id} label={tag.name} />
                ))}
              </Flex>
            )}
          </Col>
          <Col xs={false} md={4}>
            <Flex flexDirection={'column'} alignItems={'stretch'} justifyContent={'space-between'} height={'100%'}>
              <Box flex={1} pb={32}>
                <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                  <StructuredTextSummary tableOfContentLabel={articleSideTableOfContentLabel} {...content} />
                  <Box mt={24}>
                    <PostShare label={articleSideShareLabel} urlToShare={getUrlToShare()} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <PostSide headline={articleSideHeadline} button={articleSideButton} />
              </Box>
            </Flex>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default TutorialArticle
