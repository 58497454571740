import React from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Heading, Image, TemplateTag, Icons, Tag } from '../../../../atoms'
import { ITutorialCard } from '../../../../types/resources'
import { useLocale } from '../../../../hooks/useLocale'

function TutorialCard({ tutorial }: { tutorial: ITutorialCard }) {
  const { localeSlug } = useLocale()
  return (
    <Box border={'1px solid black'} boxShadow={'card'} borderRadius={8} width={'100%'} height={'100%'}>
      <Link to={`/${localeSlug}${tutorial.pageInfo.slug}`} style={{ display: 'block', height: '100%' }}>
        <Flex flexDirection={'column'} p={[5, 5, 6]} height={'100%'}>
          <Box flex={'1'}>
            <Flex justifyContent={'space-between'} width={'100%'} mb={4}>
              <Flex gap={16} alignItems={'center'}>
                <Box width={40}>
                  {tutorial.integrations && tutorial.integrations[0] && (
                    <Image
                      alt={tutorial.integrations[0].icon.alt || ''}
                      title={tutorial.integrations[0].icon.title || ''}
                      image={tutorial.integrations[0].icon.gatsbyImageData}
                      objectFit={'contain'}
                    />
                  )}
                </Box>
                <Box width={40}>
                  {tutorial.integrations && tutorial.integrations[1] && (
                    <Image
                      alt={tutorial.integrations[1].icon.alt || ''}
                      title={tutorial.integrations[1].icon.title || ''}
                      image={tutorial.integrations[1].icon.gatsbyImageData}
                      objectFit={'contain'}
                    />
                  )}
                </Box>
              </Flex>
              <Tag light color='blue' label='Tutorial' />
            </Flex>
            <Heading as={'h3'} type='heading3'>
              {tutorial.name}
            </Heading>
            <Flex mt={4} gap={'6px'} flexWrap={'wrap'}>
              {tutorial.tags.map((tag) => (
                <TemplateTag key={tag.id} label={tag.name} />
              ))}
            </Flex>
          </Box>

          <Flex mt={4} flex={'0 0 auto'} justifyContent={'flex-end'} width={'100%'}>
            <Icons.ArrowRight />
          </Flex>
        </Flex>
      </Link>
    </Box>
  )
}

export default TutorialCard
